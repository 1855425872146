exports.components = {
  "component---src-pages-de-404-jsx": () => import("./../../../src/pages-de/404.jsx" /* webpackChunkName: "component---src-pages-de-404-jsx" */),
  "component---src-pages-de-about-us-index-jsx": () => import("./../../../src/pages-de/about-us/index.jsx" /* webpackChunkName: "component---src-pages-de-about-us-index-jsx" */),
  "component---src-pages-de-agb-index-jsx": () => import("./../../../src/pages-de/agb/index.jsx" /* webpackChunkName: "component---src-pages-de-agb-index-jsx" */),
  "component---src-pages-de-blueprint-blueprint-template-jsx": () => import("./../../../src/pages-de/blueprint/_blueprintTemplate.jsx" /* webpackChunkName: "component---src-pages-de-blueprint-blueprint-template-jsx" */),
  "component---src-pages-de-case-studies-index-jsx": () => import("./../../../src/pages-de/case-studies/index.jsx" /* webpackChunkName: "component---src-pages-de-case-studies-index-jsx" */),
  "component---src-pages-de-company-articles-index-jsx": () => import("./../../../src/pages-de/company/articles/index.jsx" /* webpackChunkName: "component---src-pages-de-company-articles-index-jsx" */),
  "component---src-pages-de-company-corporate-life-index-jsx": () => import("./../../../src/pages-de/company/corporate-life/index.jsx" /* webpackChunkName: "component---src-pages-de-company-corporate-life-index-jsx" */),
  "component---src-pages-de-company-testimonials-index-jsx": () => import("./../../../src/pages-de/company/testimonials/index.jsx" /* webpackChunkName: "component---src-pages-de-company-testimonials-index-jsx" */),
  "component---src-pages-de-contact-us-index-jsx": () => import("./../../../src/pages-de/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-de-contact-us-index-jsx" */),
  "component---src-pages-de-cookies-policy-index-jsx": () => import("./../../../src/pages-de/cookies-policy/index.jsx" /* webpackChunkName: "component---src-pages-de-cookies-policy-index-jsx" */),
  "component---src-pages-de-event-event-page-slug-jsx": () => import("./../../../src/pages-de/event/{EventPage.slug}.jsx" /* webpackChunkName: "component---src-pages-de-event-event-page-slug-jsx" */),
  "component---src-pages-de-find-developers-android-index-jsx": () => import("./../../../src/pages-de/find-developers/android/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-android-index-jsx" */),
  "component---src-pages-de-find-developers-angular-index-jsx": () => import("./../../../src/pages-de/find-developers/angular/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-angular-index-jsx" */),
  "component---src-pages-de-find-developers-back-end-index-jsx": () => import("./../../../src/pages-de/find-developers/back-end/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-back-end-index-jsx" */),
  "component---src-pages-de-find-developers-c-c-plus-plus-index-jsx": () => import("./../../../src/pages-de/find-developers/c-c-plus-plus/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-c-c-plus-plus-index-jsx" */),
  "component---src-pages-de-find-developers-dot-net-index-jsx": () => import("./../../../src/pages-de/find-developers/dot-net/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-dot-net-index-jsx" */),
  "component---src-pages-de-find-developers-flutter-index-jsx": () => import("./../../../src/pages-de/find-developers/flutter/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-flutter-index-jsx" */),
  "component---src-pages-de-find-developers-front-end-index-jsx": () => import("./../../../src/pages-de/find-developers/front-end/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-front-end-index-jsx" */),
  "component---src-pages-de-find-developers-golang-index-jsx": () => import("./../../../src/pages-de/find-developers/golang/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-golang-index-jsx" */),
  "component---src-pages-de-find-developers-index-jsx": () => import("./../../../src/pages-de/find-developers/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-index-jsx" */),
  "component---src-pages-de-find-developers-ionic-index-jsx": () => import("./../../../src/pages-de/find-developers/ionic/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-ionic-index-jsx" */),
  "component---src-pages-de-find-developers-ios-index-jsx": () => import("./../../../src/pages-de/find-developers/ios/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-ios-index-jsx" */),
  "component---src-pages-de-find-developers-java-index-jsx": () => import("./../../../src/pages-de/find-developers/java/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-java-index-jsx" */),
  "component---src-pages-de-find-developers-js-index-jsx": () => import("./../../../src/pages-de/find-developers/js/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-js-index-jsx" */),
  "component---src-pages-de-find-developers-node-js-index-jsx": () => import("./../../../src/pages-de/find-developers/node-js/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-node-js-index-jsx" */),
  "component---src-pages-de-find-developers-php-index-jsx": () => import("./../../../src/pages-de/find-developers/php/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-php-index-jsx" */),
  "component---src-pages-de-find-developers-python-index-jsx": () => import("./../../../src/pages-de/find-developers/python/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-python-index-jsx" */),
  "component---src-pages-de-find-developers-react-js-index-jsx": () => import("./../../../src/pages-de/find-developers/react-js/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-react-js-index-jsx" */),
  "component---src-pages-de-find-developers-react-native-index-jsx": () => import("./../../../src/pages-de/find-developers/react-native/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-react-native-index-jsx" */),
  "component---src-pages-de-find-developers-ruby-index-jsx": () => import("./../../../src/pages-de/find-developers/ruby/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-ruby-index-jsx" */),
  "component---src-pages-de-find-developers-rust-index-jsx": () => import("./../../../src/pages-de/find-developers/rust/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-rust-index-jsx" */),
  "component---src-pages-de-find-developers-scala-index-jsx": () => import("./../../../src/pages-de/find-developers/scala/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-scala-index-jsx" */),
  "component---src-pages-de-find-developers-vue-js-index-jsx": () => import("./../../../src/pages-de/find-developers/vue-js/index.jsx" /* webpackChunkName: "component---src-pages-de-find-developers-vue-js-index-jsx" */),
  "component---src-pages-de-help-ukranian-software-developers-with-andersen-index-jsx": () => import("./../../../src/pages-de/help-ukranian-software-developers-with-andersen/index.jsx" /* webpackChunkName: "component---src-pages-de-help-ukranian-software-developers-with-andersen-index-jsx" */),
  "component---src-pages-de-impressum-index-jsx": () => import("./../../../src/pages-de/impressum/index.jsx" /* webpackChunkName: "component---src-pages-de-impressum-index-jsx" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages-de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-industries-automotive-index-jsx": () => import("./../../../src/pages-de/industries/automotive/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-automotive-index-jsx" */),
  "component---src-pages-de-industries-automotive-software-defined-vehicle-index-jsx": () => import("./../../../src/pages-de/industries/automotive/software-defined-vehicle/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-automotive-software-defined-vehicle-index-jsx" */),
  "component---src-pages-de-industries-aviation-software-development-index-jsx": () => import("./../../../src/pages-de/industries/aviation-software-development/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-aviation-software-development-index-jsx" */),
  "component---src-pages-de-industries-custom-software-for-law-firms-index-jsx": () => import("./../../../src/pages-de/industries/custom-software-for-law-firms/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-custom-software-for-law-firms-index-jsx" */),
  "component---src-pages-de-industries-e-learning-index-jsx": () => import("./../../../src/pages-de/industries/eLearning/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-e-learning-index-jsx" */),
  "component---src-pages-de-industries-ecommerce-index-jsx": () => import("./../../../src/pages-de/industries/ecommerce/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-ecommerce-index-jsx" */),
  "component---src-pages-de-industries-financial-services-banking-andersen-banking-big-data-solutions-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/banking/andersen-banking-big-data-solutions/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-banking-andersen-banking-big-data-solutions-index-jsx" */),
  "component---src-pages-de-industries-financial-services-banking-andersen-banking-digital-transformation-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/banking/andersen-banking-digital-transformation/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-banking-andersen-banking-digital-transformation-index-jsx" */),
  "component---src-pages-de-industries-financial-services-banking-andersen-banking-integration-software-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/banking/andersen-banking-integration-software/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-banking-andersen-banking-integration-software-index-jsx" */),
  "component---src-pages-de-industries-financial-services-banking-andersen-core-banking-software-solutions-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/banking/andersen-core-banking-software-solutions/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-banking-andersen-core-banking-software-solutions-index-jsx" */),
  "component---src-pages-de-industries-financial-services-banking-andersen-custom-loan-software-solutions-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/banking/andersen-custom-loan-software-solutions/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-banking-andersen-custom-loan-software-solutions-index-jsx" */),
  "component---src-pages-de-industries-financial-services-banking-andersen-digital-banking-software-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/banking/andersen-digital-banking-software/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-banking-andersen-digital-banking-software-index-jsx" */),
  "component---src-pages-de-industries-financial-services-banking-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/banking/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-banking-index-jsx" */),
  "component---src-pages-de-industries-financial-services-crypto-development-services-andersen-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/crypto-development-services-andersen/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-crypto-development-services-andersen-index-jsx" */),
  "component---src-pages-de-industries-financial-services-custom-blockchain-solutions-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/custom-blockchain-solutions/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-custom-blockchain-solutions-index-jsx" */),
  "component---src-pages-de-industries-financial-services-custom-compliance-with-andersen-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/custom-compliance-with-andersen/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-custom-compliance-with-andersen-index-jsx" */),
  "component---src-pages-de-industries-financial-services-custom-e-money-solutions-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/custom-e-money-solutions/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-custom-e-money-solutions-index-jsx" */),
  "component---src-pages-de-industries-financial-services-fintech-andersen-alternative-lending-software-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/fintech/andersen-alternative-lending-software/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-fintech-andersen-alternative-lending-software-index-jsx" */),
  "component---src-pages-de-industries-financial-services-fintech-andersen-cloud-fintech-solutions-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/fintech/andersen-cloud-fintech-solutions/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-fintech-andersen-cloud-fintech-solutions-index-jsx" */),
  "component---src-pages-de-industries-financial-services-fintech-andersen-cryptocurrency-development-services-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/fintech/andersen-cryptocurrency-development-services/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-fintech-andersen-cryptocurrency-development-services-index-jsx" */),
  "component---src-pages-de-industries-financial-services-fintech-andersen-custom-digital-wallet-development-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/fintech/andersen-custom-digital-wallet-development/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-fintech-andersen-custom-digital-wallet-development-index-jsx" */),
  "component---src-pages-de-industries-financial-services-fintech-andersen-fintech-payment-solutions-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/fintech/andersen-fintech-payment-solutions/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-fintech-andersen-fintech-payment-solutions-index-jsx" */),
  "component---src-pages-de-industries-financial-services-fintech-andersen-it-personal-finance-solutions-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/fintech/andersen-it-personal-finance-solutions/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-fintech-andersen-it-personal-finance-solutions-index-jsx" */),
  "component---src-pages-de-industries-financial-services-fintech-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/fintech/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-fintech-index-jsx" */),
  "component---src-pages-de-industries-financial-services-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-index-jsx" */),
  "component---src-pages-de-industries-financial-services-insurance-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/insurance/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-insurance-index-jsx" */),
  "component---src-pages-de-industries-financial-services-investment-andersen-asset-management-software-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/investment/andersen-asset-management-software/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-investment-andersen-asset-management-software-index-jsx" */),
  "component---src-pages-de-industries-financial-services-investment-andersen-custom-trading-software-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/investment/andersen-custom-trading-software/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-investment-andersen-custom-trading-software-index-jsx" */),
  "component---src-pages-de-industries-financial-services-investment-andersen-finance-analysis-software-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/investment/andersen-finance-analysis-software/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-investment-andersen-finance-analysis-software-index-jsx" */),
  "component---src-pages-de-industries-financial-services-investment-andersen-investment-compliance-development-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/investment/andersen-investment-compliance-development/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-investment-andersen-investment-compliance-development-index-jsx" */),
  "component---src-pages-de-industries-financial-services-investment-andersen-mobile-investment-software-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/investment/andersen-mobile-investment-software/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-investment-andersen-mobile-investment-software-index-jsx" */),
  "component---src-pages-de-industries-financial-services-investment-andersen-trading-api-development-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/investment/andersen-trading-api-development/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-investment-andersen-trading-api-development-index-jsx" */),
  "component---src-pages-de-industries-financial-services-investment-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/investment/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-investment-index-jsx" */),
  "component---src-pages-de-industries-financial-services-nft-development-services-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/nft-development-services/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-nft-development-services-index-jsx" */),
  "component---src-pages-de-industries-financial-services-open-banking-and-api-development-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/open-banking-and-api-development/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-open-banking-and-api-development-index-jsx" */),
  "component---src-pages-de-industries-financial-services-price-comparison-calculator-andersen-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/price-comparison-calculator-andersen/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-price-comparison-calculator-andersen-index-jsx" */),
  "component---src-pages-de-industries-financial-services-staff-augmentation-finance-service-companies-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/staff-augmentation-finance-service-companies/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-staff-augmentation-finance-service-companies-index-jsx" */),
  "component---src-pages-de-industries-financial-services-tax-and-accounting-software-development-index-jsx": () => import("./../../../src/pages-de/industries/financial-services/tax-and-accounting-software-development/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-financial-services-tax-and-accounting-software-development-index-jsx" */),
  "component---src-pages-de-industries-gambling-andersen-igaming-platform-development-index-jsx": () => import("./../../../src/pages-de/industries/gambling/andersen-igaming-platform-development/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-gambling-andersen-igaming-platform-development-index-jsx" */),
  "component---src-pages-de-industries-gambling-andersen-igaming-platform-protection-index-jsx": () => import("./../../../src/pages-de/industries/gambling/andersen-igaming-platform-protection/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-gambling-andersen-igaming-platform-protection-index-jsx" */),
  "component---src-pages-de-industries-gambling-andersen-igaming-production-operations-support-index-jsx": () => import("./../../../src/pages-de/industries/gambling/andersen-igaming-production-operations-support/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-gambling-andersen-igaming-production-operations-support-index-jsx" */),
  "component---src-pages-de-industries-gambling-andersen-integrations-for-igaming-index-jsx": () => import("./../../../src/pages-de/industries/gambling/andersen-integrations-for-igaming/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-gambling-andersen-integrations-for-igaming-index-jsx" */),
  "component---src-pages-de-industries-gambling-andersen-multi-devices-browsers-support-index-jsx": () => import("./../../../src/pages-de/industries/gambling/andersen-multi-devices-browsers-support/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-gambling-andersen-multi-devices-browsers-support-index-jsx" */),
  "component---src-pages-de-industries-gambling-andersen-ui-ux-game-art-design-igaming-index-jsx": () => import("./../../../src/pages-de/industries/gambling/andersen-ui-ux-game-art-design-igaming/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-gambling-andersen-ui-ux-game-art-design-igaming-index-jsx" */),
  "component---src-pages-de-industries-gambling-betting-index-jsx": () => import("./../../../src/pages-de/industries/gambling/betting/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-gambling-betting-index-jsx" */),
  "component---src-pages-de-industries-gambling-igaming-index-jsx": () => import("./../../../src/pages-de/industries/gambling/igaming/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-gambling-igaming-index-jsx" */),
  "component---src-pages-de-industries-gambling-index-jsx": () => import("./../../../src/pages-de/industries/gambling/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-gambling-index-jsx" */),
  "component---src-pages-de-industries-healthcare-ai-software-solutions-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/ai-software-solutions/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-ai-software-solutions-index-jsx" */),
  "component---src-pages-de-industries-healthcare-andersen-services-for-tech-healthcare-startups-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/andersen-services-for-tech-healthcare-startups/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-andersen-services-for-tech-healthcare-startups-index-jsx" */),
  "component---src-pages-de-industries-healthcare-assistive-technology-software-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/assistive-technology-software/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-assistive-technology-software-index-jsx" */),
  "component---src-pages-de-industries-healthcare-data-analytics-and-iomt-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/data-analytics-and-iomt/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-data-analytics-and-iomt-index-jsx" */),
  "component---src-pages-de-industries-healthcare-emr-solutions-andersen-custom-ehr-emr-development-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/emr-solutions/andersen-custom-ehr-emr-development/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-emr-solutions-andersen-custom-ehr-emr-development-index-jsx" */),
  "component---src-pages-de-industries-healthcare-emr-solutions-andersen-ehr-emr-customization-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/emr-solutions/andersen-ehr-emr-customization/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-emr-solutions-andersen-ehr-emr-customization-index-jsx" */),
  "component---src-pages-de-industries-healthcare-emr-solutions-andersen-ehr-emr-integration-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/emr-solutions/andersen-ehr-emr-integration/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-emr-solutions-andersen-ehr-emr-integration-index-jsx" */),
  "component---src-pages-de-industries-healthcare-emr-solutions-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/emr-solutions/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-emr-solutions-index-jsx" */),
  "component---src-pages-de-industries-healthcare-health-information-exchange-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/health-information-exchange/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-health-information-exchange-index-jsx" */),
  "component---src-pages-de-industries-healthcare-health-information-management-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/health-information-management/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-health-information-management-index-jsx" */),
  "component---src-pages-de-industries-healthcare-health-information-security-and-compliance-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/health-information-security-and-compliance/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-health-information-security-and-compliance-index-jsx" */),
  "component---src-pages-de-industries-healthcare-healthcare-software-developers-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/healthcare-software-developers/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-healthcare-software-developers-index-jsx" */),
  "component---src-pages-de-industries-healthcare-hospital-management-software-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/hospital-management-software/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-hospital-management-software-index-jsx" */),
  "component---src-pages-de-industries-healthcare-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-index-jsx" */),
  "component---src-pages-de-industries-healthcare-medical-practice-management-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/medical-practice-management/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-medical-practice-management-index-jsx" */),
  "component---src-pages-de-industries-healthcare-mental-health-digital-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/mental-health-digital/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-mental-health-digital-index-jsx" */),
  "component---src-pages-de-industries-healthcare-mobile-healthcare-applications-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/mobile-healthcare-applications/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-mobile-healthcare-applications-index-jsx" */),
  "component---src-pages-de-industries-healthcare-pacs-dicom-software-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/pacs-dicom-software/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-pacs-dicom-software-index-jsx" */),
  "component---src-pages-de-industries-healthcare-remote-patient-monitoring-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/remote-patient-monitoring/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-remote-patient-monitoring-index-jsx" */),
  "component---src-pages-de-industries-healthcare-revenue-cycle-management-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/revenue-cycle-management/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-revenue-cycle-management-index-jsx" */),
  "component---src-pages-de-industries-healthcare-telehealth-solutions-index-jsx": () => import("./../../../src/pages-de/industries/healthcare/telehealth-solutions/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-healthcare-telehealth-solutions-index-jsx" */),
  "component---src-pages-de-industries-index-jsx": () => import("./../../../src/pages-de/industries/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-index-jsx" */),
  "component---src-pages-de-industries-logistics-blockchain-solutions-for-logistics-supply-chains-index-jsx": () => import("./../../../src/pages-de/industries/logistics/blockchain-solutions-for-logistics-supply-chains/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-logistics-blockchain-solutions-for-logistics-supply-chains-index-jsx" */),
  "component---src-pages-de-industries-logistics-custom-fleet-management-software-development-index-jsx": () => import("./../../../src/pages-de/industries/logistics/custom-fleet-management-software-development/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-logistics-custom-fleet-management-software-development-index-jsx" */),
  "component---src-pages-de-industries-logistics-custom-software-car-sharing-pooling-rental-index-jsx": () => import("./../../../src/pages-de/industries/logistics/custom-software-car-sharing-pooling-rental/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-logistics-custom-software-car-sharing-pooling-rental-index-jsx" */),
  "component---src-pages-de-industries-logistics-custom-supply-chain-software-andersen-index-jsx": () => import("./../../../src/pages-de/industries/logistics/custom-supply-chain-software-andersen/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-logistics-custom-supply-chain-software-andersen-index-jsx" */),
  "component---src-pages-de-industries-logistics-freight-forwarding-optimization-solutions-index-jsx": () => import("./../../../src/pages-de/industries/logistics/freight-forwarding-optimization-solutions/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-logistics-freight-forwarding-optimization-solutions-index-jsx" */),
  "component---src-pages-de-industries-logistics-index-jsx": () => import("./../../../src/pages-de/industries/logistics/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-logistics-index-jsx" */),
  "component---src-pages-de-industries-logistics-inventory-management-index-jsx": () => import("./../../../src/pages-de/industries/logistics/inventory-management/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-logistics-inventory-management-index-jsx" */),
  "component---src-pages-de-industries-logistics-order-management-index-jsx": () => import("./../../../src/pages-de/industries/logistics/order-management/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-logistics-order-management-index-jsx" */),
  "component---src-pages-de-industries-logistics-smart-city-solutions-andersen-index-jsx": () => import("./../../../src/pages-de/industries/logistics/smart-city-solutions-andersen/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-logistics-smart-city-solutions-andersen-index-jsx" */),
  "component---src-pages-de-industries-logistics-transport-management-index-jsx": () => import("./../../../src/pages-de/industries/logistics/transport-management/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-logistics-transport-management-index-jsx" */),
  "component---src-pages-de-industries-logistics-urban-mobility-solutions-logistics-andersen-index-jsx": () => import("./../../../src/pages-de/industries/logistics/urban-mobility-solutions-logistics-andersen/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-logistics-urban-mobility-solutions-logistics-andersen-index-jsx" */),
  "component---src-pages-de-industries-logistics-warehouse-management-index-jsx": () => import("./../../../src/pages-de/industries/logistics/warehouse-management/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-logistics-warehouse-management-index-jsx" */),
  "component---src-pages-de-industries-manufacturing-index-jsx": () => import("./../../../src/pages-de/industries/manufacturing/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-manufacturing-index-jsx" */),
  "component---src-pages-de-industries-media-and-entertainment-digital-asset-management-index-jsx": () => import("./../../../src/pages-de/industries/media-and-entertainment/digital-asset-management/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-media-and-entertainment-digital-asset-management-index-jsx" */),
  "component---src-pages-de-industries-media-and-entertainment-index-jsx": () => import("./../../../src/pages-de/industries/media-and-entertainment/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-media-and-entertainment-index-jsx" */),
  "component---src-pages-de-industries-media-and-entertainment-live-streaming-index-jsx": () => import("./../../../src/pages-de/industries/media-and-entertainment/live-streaming/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-media-and-entertainment-live-streaming-index-jsx" */),
  "component---src-pages-de-industries-media-and-entertainment-media-security-solutions-index-jsx": () => import("./../../../src/pages-de/industries/media-and-entertainment/media-security-solutions/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-media-and-entertainment-media-security-solutions-index-jsx" */),
  "component---src-pages-de-industries-media-and-entertainment-social-media-applications-development-index-jsx": () => import("./../../../src/pages-de/industries/media-and-entertainment/social-media-applications-development/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-media-and-entertainment-social-media-applications-development-index-jsx" */),
  "component---src-pages-de-industries-media-and-entertainment-video-on-demand-index-jsx": () => import("./../../../src/pages-de/industries/media-and-entertainment/video-on-demand/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-media-and-entertainment-video-on-demand-index-jsx" */),
  "component---src-pages-de-industries-oil-and-gas-industry-custom-software-andersen-index-jsx": () => import("./../../../src/pages-de/industries/oil-and-gas-industry-custom-software-andersen/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-oil-and-gas-industry-custom-software-andersen-index-jsx" */),
  "component---src-pages-de-industries-real-estate-index-jsx": () => import("./../../../src/pages-de/industries/real-estate/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-real-estate-index-jsx" */),
  "component---src-pages-de-industries-retail-index-jsx": () => import("./../../../src/pages-de/industries/retail/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-retail-index-jsx" */),
  "component---src-pages-de-industries-software-for-life-science-andersen-craig-lipset-talk-about-pharma-index-jsx": () => import("./../../../src/pages-de/industries/software-for-life-science-andersen/craig-lipset-talk-about-pharma/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-software-for-life-science-andersen-craig-lipset-talk-about-pharma-index-jsx" */),
  "component---src-pages-de-industries-software-for-life-science-andersen-custom-femtech-software-solutions-index-jsx": () => import("./../../../src/pages-de/industries/software-for-life-science-andersen/custom-femtech-software-solutions/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-software-for-life-science-andersen-custom-femtech-software-solutions-index-jsx" */),
  "component---src-pages-de-industries-software-for-life-science-andersen-index-jsx": () => import("./../../../src/pages-de/industries/software-for-life-science-andersen/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-software-for-life-science-andersen-index-jsx" */),
  "component---src-pages-de-industries-software-for-life-science-andersen-software-for-medical-devices-andersen-index-jsx": () => import("./../../../src/pages-de/industries/software-for-life-science-andersen/software-for-medical-devices-andersen/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-software-for-life-science-andersen-software-for-medical-devices-andersen-index-jsx" */),
  "component---src-pages-de-industries-software-for-life-science-andersen-software-for-pharmaceutical-businesses-andersen-index-jsx": () => import("./../../../src/pages-de/industries/software-for-life-science-andersen/software-for-pharmaceutical-businesses-andersen/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-software-for-life-science-andersen-software-for-pharmaceutical-businesses-andersen-index-jsx" */),
  "component---src-pages-de-industries-telecom-index-jsx": () => import("./../../../src/pages-de/industries/telecom/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-telecom-index-jsx" */),
  "component---src-pages-de-industries-travel-hospitality-index-jsx": () => import("./../../../src/pages-de/industries/travel-hospitality/index.jsx" /* webpackChunkName: "component---src-pages-de-industries-travel-hospitality-index-jsx" */),
  "component---src-pages-de-privacy-policy-index-jsx": () => import("./../../../src/pages-de/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-de-privacy-policy-index-jsx" */),
  "component---src-pages-de-project-cases-project-page-slug-jsx": () => import("./../../../src/pages-de/project-cases/{ProjectPage.slug}.jsx" /* webpackChunkName: "component---src-pages-de-project-cases-project-page-slug-jsx" */),
  "component---src-pages-de-services-andersen-saas-development-services-index-jsx": () => import("./../../../src/pages-de/services/andersen-saas-development-services/index.jsx" /* webpackChunkName: "component---src-pages-de-services-andersen-saas-development-services-index-jsx" */),
  "component---src-pages-de-services-architecture-andersen-aaas-architecture-as-a-service-index-jsx": () => import("./../../../src/pages-de/services/architecture/andersen-aaas-architecture-as-a-service/index.jsx" /* webpackChunkName: "component---src-pages-de-services-architecture-andersen-aaas-architecture-as-a-service-index-jsx" */),
  "component---src-pages-de-services-architecture-index-jsx": () => import("./../../../src/pages-de/services/architecture/index.jsx" /* webpackChunkName: "component---src-pages-de-services-architecture-index-jsx" */),
  "component---src-pages-de-services-artificial-intelligence-ai-tools-for-business-transformation-index-jsx": () => import("./../../../src/pages-de/services/artificial-intelligence/ai-tools-for-business-transformation/index.jsx" /* webpackChunkName: "component---src-pages-de-services-artificial-intelligence-ai-tools-for-business-transformation-index-jsx" */),
  "component---src-pages-de-services-artificial-intelligence-consulting-index-jsx": () => import("./../../../src/pages-de/services/artificial-intelligence/consulting/index.jsx" /* webpackChunkName: "component---src-pages-de-services-artificial-intelligence-consulting-index-jsx" */),
  "component---src-pages-de-services-artificial-intelligence-index-jsx": () => import("./../../../src/pages-de/services/artificial-intelligence/index.jsx" /* webpackChunkName: "component---src-pages-de-services-artificial-intelligence-index-jsx" */),
  "component---src-pages-de-services-business-analysts-andersen-it-project-discovery-phase-index-jsx": () => import("./../../../src/pages-de/services/business-analysts/andersen-it-project-discovery-phase/index.jsx" /* webpackChunkName: "component---src-pages-de-services-business-analysts-andersen-it-project-discovery-phase-index-jsx" */),
  "component---src-pages-de-services-business-analysts-index-jsx": () => import("./../../../src/pages-de/services/business-analysts/index.jsx" /* webpackChunkName: "component---src-pages-de-services-business-analysts-index-jsx" */),
  "component---src-pages-de-services-business-analysts-software-project-documentation-services-index-jsx": () => import("./../../../src/pages-de/services/business-analysts/software-project-documentation-services/index.jsx" /* webpackChunkName: "component---src-pages-de-services-business-analysts-software-project-documentation-services-index-jsx" */),
  "component---src-pages-de-services-cloud-development-services-amazon-web-services-index-jsx": () => import("./../../../src/pages-de/services/cloud-development-services/amazon-web-services/index.jsx" /* webpackChunkName: "component---src-pages-de-services-cloud-development-services-amazon-web-services-index-jsx" */),
  "component---src-pages-de-services-cloud-development-services-index-jsx": () => import("./../../../src/pages-de/services/cloud-development-services/index.jsx" /* webpackChunkName: "component---src-pages-de-services-cloud-development-services-index-jsx" */),
  "component---src-pages-de-services-cross-platform-development-index-jsx": () => import("./../../../src/pages-de/services/cross-platform-development/index.jsx" /* webpackChunkName: "component---src-pages-de-services-cross-platform-development-index-jsx" */),
  "component---src-pages-de-services-custom-api-integration-andersen-index-jsx": () => import("./../../../src/pages-de/services/custom-api-integration-andersen/index.jsx" /* webpackChunkName: "component---src-pages-de-services-custom-api-integration-andersen-index-jsx" */),
  "component---src-pages-de-services-custom-software-development-index-jsx": () => import("./../../../src/pages-de/services/custom-software-development/index.jsx" /* webpackChunkName: "component---src-pages-de-services-custom-software-development-index-jsx" */),
  "component---src-pages-de-services-data-development-services-with-andersen-index-jsx": () => import("./../../../src/pages-de/services/data-development-services-with-andersen/index.jsx" /* webpackChunkName: "component---src-pages-de-services-data-development-services-with-andersen-index-jsx" */),
  "component---src-pages-de-services-data-science-index-jsx": () => import("./../../../src/pages-de/services/data-science/index.jsx" /* webpackChunkName: "component---src-pages-de-services-data-science-index-jsx" */),
  "component---src-pages-de-services-dedicated-team-index-jsx": () => import("./../../../src/pages-de/services/dedicated-team/index.jsx" /* webpackChunkName: "component---src-pages-de-services-dedicated-team-index-jsx" */),
  "component---src-pages-de-services-design-studio-graphic-design-services-index-jsx": () => import("./../../../src/pages-de/services/design-studio/graphic-design-services/index.jsx" /* webpackChunkName: "component---src-pages-de-services-design-studio-graphic-design-services-index-jsx" */),
  "component---src-pages-de-services-design-studio-index-jsx": () => import("./../../../src/pages-de/services/design-studio/index.jsx" /* webpackChunkName: "component---src-pages-de-services-design-studio-index-jsx" */),
  "component---src-pages-de-services-design-studio-mobile-design-index-jsx": () => import("./../../../src/pages-de/services/design-studio/mobile-design/index.jsx" /* webpackChunkName: "component---src-pages-de-services-design-studio-mobile-design-index-jsx" */),
  "component---src-pages-de-services-design-studio-redesign-ui-ux-index-jsx": () => import("./../../../src/pages-de/services/design-studio/redesign-ui-ux/index.jsx" /* webpackChunkName: "component---src-pages-de-services-design-studio-redesign-ui-ux-index-jsx" */),
  "component---src-pages-de-services-design-studio-web-design-index-jsx": () => import("./../../../src/pages-de/services/design-studio/web-design/index.jsx" /* webpackChunkName: "component---src-pages-de-services-design-studio-web-design-index-jsx" */),
  "component---src-pages-de-services-devops-index-jsx": () => import("./../../../src/pages-de/services/devops/index.jsx" /* webpackChunkName: "component---src-pages-de-services-devops-index-jsx" */),
  "component---src-pages-de-services-discovery-phase-index-jsx": () => import("./../../../src/pages-de/services/discovery-phase/index.jsx" /* webpackChunkName: "component---src-pages-de-services-discovery-phase-index-jsx" */),
  "component---src-pages-de-services-index-jsx": () => import("./../../../src/pages-de/services/index.jsx" /* webpackChunkName: "component---src-pages-de-services-index-jsx" */),
  "component---src-pages-de-services-iot-development-services-index-jsx": () => import("./../../../src/pages-de/services/iot-development-services/index.jsx" /* webpackChunkName: "component---src-pages-de-services-iot-development-services-index-jsx" */),
  "component---src-pages-de-services-it-integration-and-optimization-index-jsx": () => import("./../../../src/pages-de/services/it-integration-and-optimization/index.jsx" /* webpackChunkName: "component---src-pages-de-services-it-integration-and-optimization-index-jsx" */),
  "component---src-pages-de-services-legacy-system-modernization-index-jsx": () => import("./../../../src/pages-de/services/legacy-system-modernization/index.jsx" /* webpackChunkName: "component---src-pages-de-services-legacy-system-modernization-index-jsx" */),
  "component---src-pages-de-services-maintenance-support-index-jsx": () => import("./../../../src/pages-de/services/maintenance-support/index.jsx" /* webpackChunkName: "component---src-pages-de-services-maintenance-support-index-jsx" */),
  "component---src-pages-de-services-managed-it-help-desk-services-index-jsx": () => import("./../../../src/pages-de/services/managed-it-help-desk-services/index.jsx" /* webpackChunkName: "component---src-pages-de-services-managed-it-help-desk-services-index-jsx" */),
  "component---src-pages-de-services-managed-soc-as-a-service-index-jsx": () => import("./../../../src/pages-de/services/managed-soc-as-a-service/index.jsx" /* webpackChunkName: "component---src-pages-de-services-managed-soc-as-a-service-index-jsx" */),
  "component---src-pages-de-services-mobile-development-index-jsx": () => import("./../../../src/pages-de/services/mobile-development/index.jsx" /* webpackChunkName: "component---src-pages-de-services-mobile-development-index-jsx" */),
  "component---src-pages-de-services-mvp-development-services-andersen-index-jsx": () => import("./../../../src/pages-de/services/mvp-development-services-andersen/index.jsx" /* webpackChunkName: "component---src-pages-de-services-mvp-development-services-andersen-index-jsx" */),
  "component---src-pages-de-services-penetration-testing-services-index-jsx": () => import("./../../../src/pages-de/services/penetration-testing-services/index.jsx" /* webpackChunkName: "component---src-pages-de-services-penetration-testing-services-index-jsx" */),
  "component---src-pages-de-services-product-development-services-index-jsx": () => import("./../../../src/pages-de/services/product-development-services/index.jsx" /* webpackChunkName: "component---src-pages-de-services-product-development-services-index-jsx" */),
  "component---src-pages-de-services-progressive-web-app-development-index-jsx": () => import("./../../../src/pages-de/services/progressive-web-app-development/index.jsx" /* webpackChunkName: "component---src-pages-de-services-progressive-web-app-development-index-jsx" */),
  "component---src-pages-de-services-project-managers-index-jsx": () => import("./../../../src/pages-de/services/project-managers/index.jsx" /* webpackChunkName: "component---src-pages-de-services-project-managers-index-jsx" */),
  "component---src-pages-de-services-quality-assurance-services-automation-testing-services-index-jsx": () => import("./../../../src/pages-de/services/quality-assurance-services/automation-testing-services/index.jsx" /* webpackChunkName: "component---src-pages-de-services-quality-assurance-services-automation-testing-services-index-jsx" */),
  "component---src-pages-de-services-quality-assurance-services-custom-qa-services-index-jsx": () => import("./../../../src/pages-de/services/quality-assurance-services/custom-qa-services/index.jsx" /* webpackChunkName: "component---src-pages-de-services-quality-assurance-services-custom-qa-services-index-jsx" */),
  "component---src-pages-de-services-quality-assurance-services-index-jsx": () => import("./../../../src/pages-de/services/quality-assurance-services/index.jsx" /* webpackChunkName: "component---src-pages-de-services-quality-assurance-services-index-jsx" */),
  "component---src-pages-de-services-quality-assurance-services-performance-testing-services-index-js": () => import("./../../../src/pages-de/services/quality-assurance-services/performance-testing-services/index.js" /* webpackChunkName: "component---src-pages-de-services-quality-assurance-services-performance-testing-services-index-js" */),
  "component---src-pages-de-services-quality-assurance-services-qa-audit-services-index-jsx": () => import("./../../../src/pages-de/services/quality-assurance-services/qa-audit-services/index.jsx" /* webpackChunkName: "component---src-pages-de-services-quality-assurance-services-qa-audit-services-index-jsx" */),
  "component---src-pages-de-services-quality-assurance-services-qa-management-index-jsx": () => import("./../../../src/pages-de/services/quality-assurance-services/qa-management/index.jsx" /* webpackChunkName: "component---src-pages-de-services-quality-assurance-services-qa-management-index-jsx" */),
  "component---src-pages-de-services-security-management-index-jsx": () => import("./../../../src/pages-de/services/security-management/index.jsx" /* webpackChunkName: "component---src-pages-de-services-security-management-index-jsx" */),
  "component---src-pages-de-services-software-engineering-services-index-jsx": () => import("./../../../src/pages-de/services/software-engineering-services/index.jsx" /* webpackChunkName: "component---src-pages-de-services-software-engineering-services-index-jsx" */),
  "component---src-pages-de-services-software-team-staff-augmentation-index-jsx": () => import("./../../../src/pages-de/services/software-team-staff-augmentation/index.jsx" /* webpackChunkName: "component---src-pages-de-services-software-team-staff-augmentation-index-jsx" */),
  "component---src-pages-de-services-web-development-index-jsx": () => import("./../../../src/pages-de/services/web-development/index.jsx" /* webpackChunkName: "component---src-pages-de-services-web-development-index-jsx" */)
}

